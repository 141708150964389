import React, { Component } from 'react';
import { Col, Table, Alert, Card, Spinner } from "react-bootstrap";
import Moment from 'react-moment';
import settings from "../settings";
import '../Global.css'
import socket from '../lib/ws-con';

const LOGS_ENDPOINT = `${settings.BASE_ENDPOINT}${settings.LOGS_ENDPOINT}`;

interface MyProps {
    handleUserInput?: any
}
interface MyState {
    logsDb?: any
    logsSocket?: any
    checking?: boolean
    error?: any
}
class LogsPanel extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            logsDb: [],
            logsSocket: [],
            checking: false,
            error: false
        };
    }
    componentDidMount() {
        this.fetchDbLogs();
        this.initConnection();
    }


    initConnection() {
        socket.on('logData', (data: any) => {
            this.setState({ logsSocket: [...this.state.logsSocket, data] })
        })
    }

    async fetchDbLogs() {
        await this.setState({ checking: true })
        try {
            let resp = await fetch(`${LOGS_ENDPOINT}`);
            if (resp.status !== 200) throw new Error('Status is ' + resp.status);
            let rjson = await resp.json();
            if (rjson.success) {
                await this.setState({ logsDb: rjson.result, error: false })

            } else {
                this.setState({ error: rjson.message });
            }
        } catch (error) {
            await this.setState({ error: 'Error trying to fetch - ' + error.message });
            console.log(error);
        }
        await this.setState({ checking: false })
    }

    onClearArray = () => {
        this.setState({ logsSocket: [] });
    };

    displayError(error: string) {
        return (
            <Alert variant="danger" className="mt-3">
                <strong>Oops!</strong> {error}
            </Alert>
        )
    }

    render() {
        const requestUserFetch = (username: string) => {
            this.props.handleUserInput(username);
        }

        const trinText = (text: string) => {
            if (text.length > 20) {
                return text.substring(0, 20) + '...'
            }
            return text;
        }

        let loading = () => {
            if (this.state.logsDb.length === 0) {
                return (
                    <div className="w-100 mt-4 text-center">
                        <Spinner animation="grow" size="sm" className="mr-1" />
                        <Spinner animation="grow" size="sm" className="mr-1" />
                        <Spinner animation="grow" size="sm" className="mr-1" />
                    </div>
                )
            }
        }

        return (
            <Col className="mt-3" xs="7">
                <Card className="shadow-sm">
                    <Card.Body>
                        <p className="h4">Latest logs</p>
                        {this.state.error ? this.displayError(this.state.error) : ''}
                        {loading()}
                        <div className="logs-container">
                            <Table striped bordered size="sm">
                                <tbody>
                                    {
                                        this.state.logsSocket.sort((a: any, b: any) => { return b.executedAt - a.executedAt }).map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td className="text-muted"><Moment format="LL">{item.executedAt}</Moment></td>
                                                <td><p key={i} className="mb-0"><span onClick={() => { requestUserFetch(item.issuer) }} className="cbtn-link">{item.issuer}</span> ➨ {item.cmd} {item.subcmd ? item.subcmd : ''} {item.group ? item.group : ''} to <span onClick={() => { requestUserFetch(item.to) }} className="cbtn-link">{item.to}</span>{item.reason ? ` "${trinText(item.reason)}"` : ''} {item.time ? `for ${item.time}` : ''} <Moment className="text-muted" fromNow>{item.executedAt}</Moment> </p></td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        this.state.logsDb.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td className="text-muted"><Moment format="LL">{item.executedAt}</Moment></td>
                                                <td><p key={i} className="mb-0"><span onClick={() => { requestUserFetch(item.issuer) }} className="cbtn-link">{item.issuer}</span> ➨ {item.cmd} {item.subcmd ? item.subcmd : ''} {item.group ? item.group : ''} to <span onClick={() => { requestUserFetch(item.to) }} className="cbtn-link">{item.to}</span>{item.reason ? ` "${trinText(item.reason)}"` : ''} {item.time ? `for ${item.time}` : ''} <Moment className="text-muted" fromNow>{item.executedAt}</Moment> </p></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}


export default LogsPanel;
