import React, { Component } from 'react';
import { Col, Alert, Card, Button, Modal, Table } from "react-bootstrap";
import '../Global.css'
import socket from '../lib/ws-con';
import settings from '../settings';

const STATS_ENDPOINT = `${settings.BASE_ENDPOINT}${settings.STATS_ENDPOINT}`;
interface MyProps {
    handleUserInput?: any
}
interface MyState {
    botStats?: any
    playersToDisplay?: any[]
    showPlayersModal?: boolean
    error?: any
}
class StatsPanel extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            botStats: {},
            playersToDisplay: [],
            showPlayersModal: false,
            error: false
        };

        this.playersModal = this.playersModal.bind(this);
    }
    componentDidMount() {
        this.getFirstStats();
        this.initConnection();
    }


    initConnection() {
        socket.on('botStats', (data: any) => {
            this.setState({ botStats: data })
        })
    }

    async getFirstStats() {
        let resp = await fetch(STATS_ENDPOINT);
        let data = await resp.json();
        if (data.success) {
            this.setState({ botStats: data.result })
        }
    }

    handleCloseModal(modal: string) {
        switch (modal) {
            case 'players': {
                this.setState({ showPlayersModal: false })
                break;
            }
        }
    }

    handleOpenModal(modal: string) {
        switch (modal) {
            case 'players': {
                this.setState({ showPlayersModal: true })
                break;
            }
        }
    }

    playersModal() {
        const requestUserFetch = (username: string) => {
            this.props.handleUserInput(username);
        }
        return (
            <div className="d-inline">
                <Modal backdrop="static"
                    size="sm"
                    keyboard={false}
                    show={this.state.showPlayersModal}
                    onHide={() => { this.handleCloseModal('players') }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Player list</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-muted">Use CTRL + F for search!</p>
                        <div className="modal-max">
                            <Table striped bordered size="sm">
                                <tbody>
                                    {
                                        this.state.playersToDisplay?.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td> <p className="mb-0 cbtn-link" onClick={() => { requestUserFetch(item.username); this.handleCloseModal('players') }}>{item.username}</p></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => { this.handleCloseModal('players') }}>
                            Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    displayError(error: string) {
        return (
            <Alert variant="danger" className="mt-3">
                <strong>Oops!</strong> {error}
            </Alert>
        )
    }

    render() {
        const updatePlayersToDisplay = (players: any[]) => {
            this.setState({ playersToDisplay: players })
        }
        const renderOnlineColor = (num: number) => {
            if (num == null) return null;
            if (num <= 50) {
                return (
                    <span className="text-danger">{num}</span>
                )
            } else if (num <= 80) {
                return (
                    <span className="text-warning">{num}</span>
                )
            } else {
                return (
                    <span className="text-success">{num}</span>
                )
            }
        }

        return (
            <Col className="mt-3 pr-0" xs="12">
                <Card className="shadow-sm">
                    <Card.Body>
                        <p className="h4 mb-3">House stats</p>
                        <div className="d-flex justify-content-around">
                            <div className="p-3 bg-light rounded">
                                <p className="my-0">Online players:</p>
                                <p className="my-0"><span className="h3">{this.state.botStats?.playerCount ? renderOnlineColor(this.state.botStats.playerCount) : '...'}</span> <small className="text-muted cbtn-link" onClick={() => { this.handleOpenModal('players'); updatePlayersToDisplay(this.state.botStats?.allPlayers) }}>List</small></p>
                            </div>
                            <div className="p-3 bg-light rounded pointer">
                                <p className="my-0">Online Trusted Players:</p>
                                <p className="my-0"> <span className="h3">{this.state.botStats?.onlineTrustedPlayers ? this.state.botStats.onlineTrustedPlayers.length : '...'}/{this.state.botStats?.totalTrustedPlayersCount ? this.state.botStats.totalTrustedPlayersCount : '...'}</span> <small className="text-muted cbtn-link" onClick={() => { this.handleOpenModal('players'); updatePlayersToDisplay(this.state.botStats?.onlineTrustedPlayers) }}>List</small></p>
                                <this.playersModal />
                            </div>
                            <div className="p-3 bg-light rounded">
                                <p className="my-0">Online Mod Players:</p>
                                <p className="my-0"> <span className="h3">{this.state.botStats?.onlineMods ? this.state.botStats.onlineMods.length : '...'}/{this.state.botStats?.totalModsCount ? this.state.botStats.totalModsCount : '...'}</span> <small className="text-muted cbtn-link" onClick={() => { this.handleOpenModal('players'); updatePlayersToDisplay(this.state.botStats?.onlineMods) }}>List</small></p>
                                <this.playersModal />
                            </div>
                        </div>

                    </Card.Body>
                </Card>
            </Col>
        );
    }
}


export default StatsPanel;
