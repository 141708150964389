import React, { Component } from "react";
import { Button } from "react-bootstrap";
import settings from "../settings";

interface MyProps {
  isLoading?: boolean;
  error?: any;
  parentCallback: any;
}
interface MyState {
  usernameLoginInput: string;
  secretInput: string;

  isLoading?: boolean;
  error?: any;
  isAdmin?: boolean;
}

class Login extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.state = {
      usernameLoginInput: "",
      secretInput: "",

      isLoading: false,
      error: false,
      isAdmin: false,
    };

    this.doLogin = this.doLogin.bind(this);
    this.updateSecretInput = this.updateSecretInput.bind(this);
    this.updateUsernameLoginInput =
      this.updateUsernameLoginInput.bind(this);
  }

  sendData(loginData: any) {
    this.props.parentCallback(loginData);
  }

  updateUsernameLoginInput(event: any) {
    this.setState({ usernameLoginInput: event.target.value });
  }

  updateSecretInput(event: any) {
    this.setState({ secretInput: event.target.value });
  }

  componentWillReceiveProps(props: any) {
    this.setState({ isLoading: props.isLoading, error: props.error });
  }

  async doLogin() {
    await this.setState({ isLoading: true, error: false });
    if (
      this.state.usernameLoginInput &&
      this.state.secretInput &&
      this.state.secretInput.length > 25
    ) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("username", this.state.usernameLoginInput);
      urlencoded.append("secret", this.state.secretInput);

      let loginData = await fetch(
        `${settings.BASE_ENDPOINT}${settings.SESSION_ENDPOINT}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: urlencoded,
        }
      );
      if (loginData.status !== 200) {
        await this.setState({
          isLoading: false,
          error: "Server issues. Try again later.",
        });
        return;
      }
      let l = await loginData.json();
      if (l.session) {
        this.sendData({
          session: l.session,
          user: l.user,
          isAdmin: l.isAdmin,
          uuid: l.uuid,
          group: l.group,
        });
        return;
      } else {
        await this.setState({
          error: "Invalid username or secret key.",
        });
      }
    } else {
      await this.setState({
        error: "Invalid username or secret key.",
      });
    }
    await this.setState({ isLoading: false });
  }

  render() {
    let errorMsg = (error: any) => {
      if (!error) return null;
      return (
        <div className="alert alert-danger" role="alert">
          <strong>Error:</strong> {error}
        </div>
      );
    };
    return (
      <div className="container vh-100">
        <div className="row align-items-center h-100">
          <div className="col-12 col-lg-4">
            <div className="d-flex justify-content-start">
              <div className="card shadow m-card w-100">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      className="mb-3"
                      src="/images/1.webp"
                      alt="welcome"
                    />
                  </div>
                  <h5 className="card-title">Access </h5>
                  <hr className="my-3" />
                  <div className="mb-3">
                    <input
                      type="text"
                      onChange={this.updateUsernameLoginInput}
                      className="form-control form-control-sm"
                      id="input-email"
                      placeholder="Your minecraft username"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      onChange={this.updateSecretInput}
                      className="form-control form-control-sm"
                      id="input-password"
                      placeholder="Your secret key"
                    />
                  </div>
                  {errorMsg(this.state.error)}
                  <Button
                    variant="primary"
                    size="sm"
                    disabled={this.state.isLoading}
                    onClick={this.doLogin}>
                    {this.state.isLoading ? "Wait..." : "Login"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
