import React, { Component } from "react";
import {
  Form,
  Button,
  Col,
  Alert,
  Modal,
  Table,
  Row,
  Card,
  Spinner,
} from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import settings from "../settings";
import "../Global.css";

const API_USERDATA_ENDPONT = `${settings.BASE_ENDPOINT}${settings.USERDATA_ENDPOINT}`;
const API_HYPIXELDATA_ENDPONT = `${settings.BASE_ENDPOINT}${settings.HYPIXELDATA_ENDPOINT}`;
const API_MODLOGS_ENDPONT = `${settings.BASE_ENDPOINT}${settings.MODLOGS_ENDPOINT}`;

interface MyProps {
  requestForUserFromExternal?: any;
}
interface MyState {
  usernameInput?: any;
  error?: any;
  checking?: boolean;
  userResult?: any;
  showModLogsModal: boolean;
  showCookiesModal: boolean;
  herror?: any;
  merror?: any;
  checkingModLogs?: boolean;
  modLogs?: any;
  noModLogs?: boolean;
}
class UserFinder extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.state = {
      usernameInput: "",
      checking: false,
      error: false,
      userResult: false,
      showModLogsModal: false,
      showCookiesModal: false,
      herror: false,
      merror: false,
      checkingModLogs: false,
      modLogs: [],
      noModLogs: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fecthUserData = this.fecthUserData.bind(this);
    this.displayUserData = this.displayUserData.bind(this);
    this.modLogsModal = this.modLogsModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.cookiesModal = this.cookiesModal.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  async handleInputChange(event: any) {
    await this.setState({ usernameInput: event.target.value });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.fecthUserData();
  }

  async componentWillReceiveProps(props: any) {
    await this.setState({
      usernameInput: props.requestForUserFromExternal,
    });
    this.fecthUserData();
  }

  async fecthUserData() {
    if (this.state.usernameInput.length < 1) {
      return null;
    }
    await this.clearState();
    await this.setState({ checking: true, modLogs: [] });
    let resp;
    let rjson;
    let hrjson;
    try {
      resp = await fetch(
        `${API_USERDATA_ENDPONT}${this.state.usernameInput}`
      );
      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      rjson = await resp.json();
      if (rjson.success) {
        await this.setState({
          userResult: rjson.result,
          error: false,
        });
      } else {
        this.setState({ error: rjson.message });
      }
    } catch (error) {
      console.log(error);
      await this.setState({
        error: "Error trying to fetch - " + error.message,
      });
    }
    await this.setState({ checking: false });
    try {
      resp = await fetch(
        `${API_HYPIXELDATA_ENDPONT}${rjson.result.uuid}`
      );
      if (resp.status !== 200)
        await this.setState({ herror: `Error: Hypixel API down?` });
      hrjson = await resp.json();
      if (!hrjson.success) {
        await this.setState({ herror: `Error: ${hrjson.message}` });
      } else {
        await this.setState({
          userResult: {
            ...this.state.userResult,
            hypixel: hrjson.result.hypixel,
          },
        });
      }
    } catch (error) {
      await this.setState({ herror: `Error: ${error.message}` });
    }
  }

  async clearState() {
    await this.setState({
      userResult: false,
      herror: false,
      error: false,
      noModLogs: false,
    });
  }
  clearInput() {
    this.setState({
      userResult: false,
      usernameInput: "",
      herror: false,
      error: false,
    });
  }

  displayLogsData(logs: any) {
    if (!logs) {
      return null;
    }
    const requestFetchUserLog = async (user: string) => {
      this.handleCloseModal("modlogs");
      await this.setState({ usernameInput: user });
      this.fecthUserData();
    };
    return (
      <div className="ml-2">
        {logs.map((item: any, i: number) => (
          <p key={i} className="mb-0">
            ➨ {item.cmd} {item.subcmd ? item.subcmd : ""}{" "}
            {item.group ? item.group : ""} by{" "}
            <span
              className="cbtn-link"
              onClick={() => {
                requestFetchUserLog(item.issuer);
              }}>
              {item.issuer}
            </span>
            {item.reason ? ` "${item.reason}"` : ""}{" "}
            {item.time ? `for ${item.time}` : ""}{" "}
            <span
              title={moment(item.executedAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}>
              <Moment className="text-muted" fromNow>
                {item.executedAt}
              </Moment>{" "}
            </span>
          </p>
        ))}
      </div>
    );
  }

  handleCloseModal(modal: string) {
    switch (modal) {
      case "modlogs": {
        this.setState({ showModLogsModal: false });
        break;
      }
      case "cookies": {
        this.setState({ showCookiesModal: false });
        break;
      }
    }
  }
  handleOpenModal(modal: string) {
    switch (modal) {
      case "modlogs": {
        this.setState({ showModLogsModal: true });
        break;
      }
      case "cookies": {
        this.setState({ showCookiesModal: true });
        break;
      }
    }
  }

  cookiesModal(props: any) {
    if (!props.cookies || props.cookies.length === 0) {
      return null;
    }
    let cookies = props.cookies;

    let noCookies = () => {
      return (
        <span className="small text-muted">
          Did not give cookies to any house
        </span>
      );
    };

    return (
      <div>
        <Button
          className="my-2"
          variant="secondary"
          size="sm"
          onClick={() => {
            this.handleOpenModal("cookies");
          }}>
          Show cookies history
        </Button>
        <Modal
          backdrop="static"
          size="xl"
          keyboard={false}
          show={this.state.showCookiesModal}
          onHide={() => {
            this.handleCloseModal("cookies");
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Cookies history</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-max">
              <Table striped bordered size="sm">
                <tbody>
                  {cookies.map((item: any, i: number) => (
                    <tr key={i}>
                      <td
                        className="text-muted"
                        title={`Week: ${item.metaData.week} - Year: ${item.metaData.weekYear}`}>
                        <Moment format="LL">
                          {item.metaData.weekStartDate}
                        </Moment>{" "}
                        - Week {item.metaData.week}{" "}
                        {item.metaData.isGhostWeek ? "👻" : ""}
                      </td>
                      <td>
                        {" "}
                        <p key={i} className="mb-0">
                          {" "}
                          {item.gaveCookies ? "🍪" : "❌"}{" "}
                          {item.inactive ? noCookies() : ""}{" "}
                          {i === cookies.length - 1
                            ? " - First week"
                            : ""}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                this.handleCloseModal("cookies");
              }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  modLogsModal(props: any) {
    if (!props.uuid) {
      return null;
    }
    const requestFetchUserModLog = async (user: string) => {
      this.handleCloseModal("modlogs");
      await this.setState({ usernameInput: user });
      this.fecthUserData();
    };
    let logs = this.state.modLogs;

    const fetchModLogs = async () => {
      try {
        await this.setState({ checkingModLogs: true });
        let resp = await fetch(`${API_MODLOGS_ENDPONT}${props.uuid}`);
        if (resp.status !== 200)
          throw new Error("Status is " + resp.status);
        let data = await resp.json();
        if (data.result.length > 0) {
          await this.setState({
            modLogs: data.result,
            checkingModLogs: false,
          });
        } else {
          await this.setState({
            noModLogs: true,
            checkingModLogs: false,
          });
        }
      } catch (error) {
        await this.setState({
          merror: `Error: ${error.message}`,
          checkingModLogs: false,
        });
      }
    };
    if (this.state.checkingModLogs) {
      return (
        <div>
          <Spinner animation="grow" size="sm" className="mr-1" />
          <Spinner animation="grow" size="sm" className="mr-1" />
          <Spinner animation="grow" size="sm" className="mr-1" />
        </div>
      );
    }

    if (this.state.noModLogs) {
      return <p className="text-danger">This user has no mod logs</p>;
    } else if (logs.length > 0) {
      return (
        <div>
          <Button
            className="my-2"
            variant="secondary"
            size="sm"
            onClick={() => {
              this.handleOpenModal("modlogs");
            }}>
            Show mod logs
          </Button>
          <Modal
            backdrop="static"
            size="xl"
            keyboard={false}
            show={this.state.showModLogsModal}
            onHide={() => {
              this.handleCloseModal("modlogs");
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Mod logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-muted">Use CTRL + F for search!</p>
              <div className="modal-max">
                <Table striped bordered size="sm">
                  <tbody>
                    {logs.map((item: any, i: number) => (
                      <tr key={i}>
                        <td className="text-muted">
                          <Moment format="LL">
                            {item.executedAt}
                          </Moment>
                        </td>
                        <td>
                          {" "}
                          <p key={i} className="mb-0">
                            ➨ {item.cmd}{" "}
                            {item.subcmd ? item.subcmd : ""}{" "}
                            {item.group ? item.group : ""} to{" "}
                            <span
                              className="cbtn-link"
                              onClick={() => {
                                requestFetchUserModLog(item.to);
                              }}>
                              {item.to}
                            </span>
                            {item.reason ? ` "${item.reason}"` : ""}{" "}
                            {item.time ? `for ${item.time}` : ""}{" "}
                            <Moment className="text-muted" fromNow>
                              {item.executedAt}
                            </Moment>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  this.handleCloseModal("modlogs");
                }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            className="my-2"
            variant="outline-secondary"
            size="sm"
            onClick={fetchModLogs}>
            Load mod logs
          </Button>
        </div>
      );
    }
  }

  displayError(error: string) {
    return (
      <Alert variant="danger" className="mt-3">
        <strong>Oops!</strong> {error}
      </Alert>
    );
  }

  displayPunishmentData(punshData: any, punishmentType: string) {
    function parseReason(msg: string, matchedArray: any[]) {
      if (!msg) return null;
      return (
        <span>
          <span>
            Message: <span className="text-muted">{msg}</span>
          </span>
          <br />
          <span>
            Bot fired with:{" "}
            {matchedArray.map((item: any, i: number) => (
              <span key={i}>{item}</span>
            ))}
          </span>{" "}
          <br />
        </span>
      );
    }
    if (!punshData) {
      return null;
    }
    return (
      <div className="ml-2 bg-danger rounded px-2 py-1 text-light mt-1">
        <p className="mb-0">
          <span className="font-weight-bold">{punishmentType}</span>{" "}
          <br />
          Staff:{" "}
          {punshData.bannedByBot
            ? "The bot"
            : punshData.moderator}{" "}
          <br />
          Reason: {punshData.reason} <br />
          {punshData.bannedByBot
            ? parseReason(punshData.message, punshData.matchedWith)
            : ""}
          Since:{" "}
          <span
            title={moment(punshData.since).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}>
            <Moment fromNow>{punshData.since}</Moment> -{" "}
            {moment(punshData.since).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
          </span>{" "}
          <br />
          Duration: {punshData.duration} <br />
        </p>
      </div>
    );
  }

  displayUserData(uResult: any) {
    if (!uResult && !uResult.uuid) {
      return null;
    }

    let hpxlVisibility = (visibility: any) => {
      if (!visibility)
        return <small className="text-muted">cant fetch</small>;

      if (visibility.unlimited) {
        return (
          <span
            title="Visibility is UNLIMITED!"
            role="img"
            aria-label="check mark">
            👍
          </span>
        );
      } else {
        return (
          <span
            title={`Visibility is mode ${visibility.mode}`}
            role="img"
            aria-label="cross mark">
            ❌
          </span>
        );
      }
    };

    let hypixelData = () => {
      if (this.state.herror) {
        return (
          <small className="text-danger">{this.state.herror}</small>
        );
      } else {
        if (uResult.hypixel) {
          let h = uResult.hypixel;
          return (
            <div>
              <p className="mb-0">
                <span className="font-weight-bold">Status:</span>{" "}
                {h.hypixelStatus?.session?.online
                  ? "🟢 Online"
                  : "🔴 Offline/hidden"}
              </p>
              <p className="mb-0">
                <span className="font-weight-bold">Rank:</span>{" "}
                {h.hypixelData?.rank}
              </p>
              <p className="mb-0">
                <span className="font-weight-bold">MC Version:</span>{" "}
                {h.hypixelData?.mcVersion
                  ? h.hypixelData?.mcVersion
                  : " - "}
              </p>
              <p className="mb-0">
                <span className="font-weight-bold">
                  Visibility settings:
                </span>{" "}
                {hpxlVisibility(h.hypixelData?.visibility)}
              </p>
              <p className="mb-0">
                <span className="font-weight-bold">Level:</span>{" "}
                {h.hypixelData ? h?.hypixelData?.level : "Cant fetch"}
              </p>
              <p className="mb-0">
                <span className="font-weight-bold">
                  Total{" "}
                  <span role="img" aria-label="cookie">
                    🍪
                  </span>
                  :
                </span>{" "}
                {h?.hypixelData?.cookiesCounter}
              </p>
              <this.cookiesModal cookies={h.hypixelData.cookies} />
            </div>
          );
        } else {
          return <small className="text-muted">Fetching...</small>;
        }
      }
    };
    return (
      <div className="my-3 userdata-container">
        <Row>
          <Col>
            <p className="h5">Hypixel Data:</p>
            {hypixelData()}
          </Col>
          <Col>
            <p className="h5">House Data:</p>
            <p className="mb-0">
              <span className="font-weight-bold">Group:</span>{" "}
              {uResult.group ? uResult.group : "No group"}
            </p>
            <this.modLogsModal
              isMod={uResult.isMod}
              uuid={uResult.uuid}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <p className="h5">Punishment data:</p>
            {uResult?.punishmentData && (
              <div className="mt-3 ml-2">
                <p className="mb-0">
                  <span className="font-weight-bold">
                    Current punishment:
                  </span>{" "}
                  <br />
                  {!uResult?.punishmentData.banned &&
                  !uResult?.punishmentData.muted
                    ? "This user is currently not punished"
                    : ""}
                </p>
                {uResult?.punishmentData?.banned
                  ? this.displayPunishmentData(
                      uResult?.punishmentData?.banned,
                      "Banned!"
                    )
                  : ""}
                {uResult?.punishmentData?.muted
                  ? this.displayPunishmentData(
                      uResult?.punishmentData?.muted,
                      "Muted!"
                    )
                  : ""}
                <p className="mb-0 mt-2">
                  <span className="font-weight-bold">
                    Latest 50 punishment logs:{" "}
                    <span className="text-muted">{`(${
                      uResult?.punishmentData?.logs?.length || 0
                    })`}</span>
                  </span>{" "}
                  <br />
                  {uResult?.punishmentData.logs.length
                    ? ""
                    : "No punishment logs"}
                </p>
                {uResult?.punishmentData?.logs.length > 0
                  ? this.displayLogsData(
                      uResult?.punishmentData?.logs
                    )
                  : ""}
                <hr />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let loading = () => {
      if (this.state.checking) {
        return (
          <div className="w-100 mt-4 text-center">
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
          </div>
        );
      }
    };

    return (
      <Col className="mt-3 pr-0">
        <Card className="shadow-sm">
          <Card.Body>
            <p className="h4">User information</p>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Check a player</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Enter username"
                  disabled={this.state.checking}
                  value={this.state.usernameInput}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Button
                variant="info"
                size="sm"
                disabled={this.state.checking}
                onClick={this.fecthUserData}>
                Check
              </Button>
              <Button
                variant="light"
                size="sm"
                className="ml-1"
                disabled={!this.state.userResult}
                onClick={this.clearInput}>
                Clear
              </Button>
              <hr />
            </Form>
            {loading()}
            {!this.state.error
              ? this.displayUserData(this.state.userResult)
              : this.displayError(this.state.error)}
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

export default UserFinder;
