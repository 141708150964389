import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import settings from "../settings";

export function ToolUsers() {
  const [toolUsers, setToolUsers] = useState<any[]>([]);
  const [formUsername, setFormUsername] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const [loading, setLoading] = useState(false);

  const goBack = () => {
    // @ts-ignore
    window.location.reload();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    let newToolUser = {
      username: formUsername,
    };

    let resp;
    let rjson;

    try {
      resp = await fetch(
        `${settings.BASE_ENDPOINT}/toolusers/create`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toolUser: newToolUser,
          }),
        }
      );

      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      rjson = await resp.json();

      if (rjson.success) {
        setSecretKey(rjson?.result?.secret);
        await getToolUsers();
      } else {
        alert("Something went wrong! (User already exist?)");
      }
    } catch (error) {
      console.log("error ", error);
    }

    setLoading(false);
  };

  const handleUsernameChange = (e: any) => {
    setFormUsername(e?.target?.value);
  };

  const getToolUsers = async () => {
    try {
      let resp = await fetch(`${settings.BASE_ENDPOINT}/toolusers`, {
        method: "GET",
        credentials: "include",
      });

      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      let rjson = await resp.json();

      if (rjson.success) {
        setToolUsers(rjson.result);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const deleteToolUser = async (uuid: string) => {
    try {
      setLoading(true);
      let resp = await fetch(
        `${settings.BASE_ENDPOINT}/toolusers/delete`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toolUser: {
              uuid,
            },
          }),
        }
      );

      if (resp.status !== 200) {
        alert("Something went wrong!");
        throw new Error("Status is " + resp.status);
      }
      let rjson = await resp.json();

      if (rjson.success) {
        await getToolUsers();
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log("error ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getToolUsers();
  }, []);

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col xs="12">
          <Button
            className="mr-2"
            variant="danger"
            size="sm"
            onClick={goBack}>
            {"<"} Back{" "}
          </Button>
        </Col>
        <Col className="mt-3" xs="12">
          <h4>Grant access to mod tool</h4>
          <form onSubmit={handleSubmit}>
            <label htmlFor="title">Minecraft username *</label>
            <input
              onChange={handleUsernameChange}
              maxLength={100}
              className="ml-2 mb-2"
              id="title"
              type="text"
              required
            />

            <br />
            <Button
              disabled={loading}
              type="submit"
              className="mr-2"
              variant="success"
              size="sm">
              + Grant access
            </Button>
          </form>
        </Col>
        <Col xs="12">
          {secretKey && (
            <div className="mt-2">
              <h5>Secret key</h5>
              <input
                type="text"
                value={secretKey}
                contentEditable={false}
                disabled={true}
              />
              <p>
                Please copy this key. You will not be able to see it
                again.
              </p>
              <p></p>
            </div>
          )}
        </Col>
        <Col className="mt-3" xs="12">
          <h4>List of users with access</h4>
          {toolUsers?.map((toolUser: any) => (
            <div
              className="pkg-card-container active mb-2"
              key={toolUser.uuid}>
              <h5>{toolUser.username}</h5>
              <p className="m-0 small">UUID: {toolUser.uuid}</p>
              <div className="mt-2">
                {!"884bf1f1edba424e80ce6d6e2ec048592abcf51c828542dd9811ebd311690891".includes(
                  toolUser.uuid
                ) && (
                  <Button
                    onClick={() => {
                      deleteToolUser(toolUser.uuid);
                    }}
                    size="sm"
                    variant="danger"
                    className="mr-2"
                    disabled={loading}>
                    Remove
                  </Button>
                )}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}
