import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import settings from "../settings";

const PAYPAL_FEE_PERCENT = 4.25;
const PAYPAL_FIXED_FEE = 0.49;
const COMMISSIONS_MULTIPLIER = 1;

export function Package({ userUuid, group }: any) {
  const [packages, setPackages] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formPrice, setFormPrice] = useState(1);
  // const [formDiscountedPrice, setFormDiscountedPrice] = useState(0);
  // const [formDiscountPercent, setFormDiscountPercent] = useState(0);
  const [formMinecraftRequired, setFormMinecraftRequired] =
    useState("false");
  const [formDiscordRequired, setFormDiscordRequired] =
    useState("false");
  const [formIsMembership, setFormIsMembership] = useState("false");
  const [formMonth, setFormMonth] = useState(0);

  const [loading, setLoading] = useState(false);

  const goBack = () => {
    // @ts-ignore
    window.location.reload();
  };

  const openNewUrl = async (url: string) => {
    let finalUrl = `https://naizu.net/store/package/${url}`;
    window.open(finalUrl, "_blank");
    await revalidatePackage(url);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    let newPackage = {
      title: formTitle,
      description: formDescription,
      price: formPrice,
      finalPrice: calculateFinalPrice(),
      minecraftRequired: formMinecraftRequired === "true",
      discordRequired: formDiscordRequired === "true",
      withSubscription: formIsMembership === "true",
      months: formMonth,
    };

    let resp;
    let rjson;

    try {
      resp = await fetch(`${settings.BASE_ENDPOINT}/package/create`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          package: newPackage,
        }),
      });

      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      rjson = await resp.json();

      if (rjson.success) {
        await getPackages();
        alert("Package created!");
      } else {
      }
    } catch (error) {
      console.log("error ", error);
    }

    setLoading(false);
  };

  const handleTitleChange = (e: any) => {
    setFormTitle(e?.target?.value);
  };

  const handleDescriptionChange = (e: any) => {
    setFormDescription(e?.target?.value);
  };

  const handleIsMemChange = (e: any) => {
    setFormIsMembership(e?.target?.value);
  };

  const handleMonthChange = (e: any) => {
    let val = Number(e?.target?.value);
    if (isNaN(val)) return;
    setFormMonth(val);
  };

  const handleMcRequiredChange = (e: any) => {
    setFormMinecraftRequired(e?.target?.value);
  };

  const handleDiscRequiredChange = (e: any) => {
    setFormDiscordRequired(e?.target?.value);
  };

  const handlePriceChange = (e: any) => {
    let val = Number(e?.target?.value);
    if (isNaN(val)) return;
    setFormPrice(val);
  };

  const calculateFinalPrice = () => {
    let commissions =
      (formPrice / 100) *
        (PAYPAL_FEE_PERCENT * COMMISSIONS_MULTIPLIER) +
      PAYPAL_FIXED_FEE * COMMISSIONS_MULTIPLIER;
    let finalPrice = formPrice + commissions;
    return finalPrice.toFixed(2);
  };

  const getPackages = async () => {
    try {
      let resp = await fetch(`${settings.BASE_ENDPOINT}/packages`, {
        method: "GET",
        credentials: "include",
      });

      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      let rjson = await resp.json();

      if (rjson.success) {
        setPackages(rjson.result);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const updatePackageStatus = async (
    uuid: string,
    currentStatus: boolean
  ) => {
    try {
      setLoading(true);
      let resp = await fetch(
        `${settings.BASE_ENDPOINT}/package/update`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            package: {
              status: !currentStatus,
              uuid,
            },
          }),
        }
      );

      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      let rjson = await resp.json();

      if (rjson.success) {
        await getPackages();
        await revalidatePackage(uuid);
        alert("Status updated!");
      }
    } catch (error) {
      console.log("error ", error);
    }
    setLoading(false);
  };

  const deletePackage = async (uuid: string) => {
    try {
      setLoading(true);
      let resp = await fetch(
        `${settings.BASE_ENDPOINT}/package/delete`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            package: {
              uuid,
            },
          }),
        }
      );

      if (resp.status !== 200) {
        alert("Something went wrong!");
        throw new Error("Status is " + resp.status);
      }
      let rjson = await resp.json();

      if (rjson.success) {
        await getPackages();
        await revalidatePackage(uuid);
        alert("Package deleted!");
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log("error ", error);
    }
    setLoading(false);
  };

  const revalidatePackage = async (uuid: string) => {
    const resp = await fetch(
      `${settings.NAIZU_PAGE_BASE}/api/cache-revalidator?secret=dbf9fe08ef27498cbc237c2beafd9a8f&package=${uuid}`
    );
    if (resp.status !== 200) {
      console.log("Something went wrong validating package!");
      return;
    }
    const rjson = await resp.json();
    if (rjson.success) {
      console.log("Package revalidated!");
    } else {
      console.log("Something went wrong!");
    }
  };
  useEffect(() => {
    getPackages();
  }, []);

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col xs="12">
          <Button
            className="mr-2"
            variant="danger"
            size="sm"
            onClick={goBack}>
            {"<"} Back{" "}
          </Button>
        </Col>
        <Col className="mt-3" xs="12">
          <h4>Create package</h4>
          <form onSubmit={handleSubmit}>
            <label htmlFor="title">Title *</label>
            <input
              onChange={handleTitleChange}
              maxLength={100}
              className="ml-2 mb-2"
              id="title"
              type="text"
              required
            />

            <br />
            <label htmlFor="idesc">Description *</label>
            <input
              onChange={handleDescriptionChange}
              maxLength={255}
              className="ml-2 mb-2"
              id="idesc"
              type="text"
              required
            />

            <br />
            <label htmlFor="iminecraft">
              Minecraft user required{" "}
              <span
                className="hover-info"
                title="Ideal if we want to capture the buyer minecraft username. Show an input field that allows the buyer to enter their minecraft username">
                [info]
              </span>
            </label>
            <select
              className="ml-2 mb-2"
              id="iminecraft"
              onChange={handleMcRequiredChange}
              value={formMinecraftRequired}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>

            <br />
            <label htmlFor="idiscord">
              Discord user required{" "}
              <span
                className="hover-info"
                title="Ideal if we want to capture the buyer discord id. Show an button that allows the buyer to login with discord">
                [info]
              </span>
            </label>
            <select
              className="ml-2 mb-2"
              id="idiscord"
              onChange={handleDiscRequiredChange}
              value={formDiscordRequired}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
            <br />
            <label htmlFor="imemb">
              With "subscription period"{" "}
              <span
                className="hover-info"
                title="We don't have a real subscription system. However, enabling this feature will allow us to send a reminder when a subscriber's membership expires">
                [info]
              </span>
            </label>
            <select
              onChange={handleIsMemChange}
              // ts-ignore
              value={formIsMembership}
              className="ml-2 mb-2"
              id="imemb">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
            {formIsMembership == "true" && (
              <>
                <br />
                <label htmlFor="imonths">
                  Months{" "}
                  <span
                    className="hover-info"
                    title="Select the number of months that the discord bot should wait before sending a reminder. Note that the countdown will start from the moment a payment is confirmed">
                    [info]
                  </span>
                </label>
                <input
                  min={1}
                  max={99}
                  onChange={handleMonthChange}
                  className="ml-2 mb-2"
                  id="imonths"
                  type="number"
                  required
                />
              </>
            )}

            <br />
            <label htmlFor="iprice">Price (USD) *</label>
            <input
              className="ml-2 mb-2"
              min={1}
              max={999999}
              step="any"
              id="iprice"
              type="number"
              placeholder="Ex: 49.99"
              value={formPrice}
              onChange={handlePriceChange}
              required
            />

            {/* <br />
            <label htmlFor="iprice2">Price discounted</label>
            <input
              className="ml-2 mb-2"
              id="iprice2"
              type="number"
              placeholder="Ex: 29.99"
            /> */}

            {/* <br />
            <label htmlFor="iprice2">Discount %</label>
            <input
              className="ml-2 mb-2"
              id="iprice2"
              type="decimal"
              // value={getDiscountInPercent()}
              disabled
            /> */}

            <br />
            <label htmlFor="ifprice">Final price </label>
            <input
              className="ml-2 mb-2"
              id="ifprice"
              type="number"
              value={calculateFinalPrice()}
              disabled
            />
            <br />
            <Button
              disabled={loading}
              type="submit"
              className="mr-2"
              variant="success"
              size="sm">
              + Create package
            </Button>
          </form>
        </Col>
        <Col className="mt-3" xs="12">
          <h4>Package list</h4>
          {packages?.map((packageItem: any) => (
            <div
              className={`pkg-card-container ${
                packageItem.active ? "active" : "inactive"
              } mb-4`}
              key={packageItem.uuid}>
              <h5>{packageItem.title}</h5>
              <p className="m-0 small">{packageItem.uuid}</p>
              <p className="m-0">
                Active: {packageItem.active ? "Yes" : "No"}
              </p>
              <p className="m-0">
                Description: {packageItem.description}
              </p>
              <p className="m-0">
                Subscription enabled: {packageItem.with_subscriber_period ? "Yes" : "No"}
              </p>
              <p className="m-0">
                Duration: {packageItem?.months ? `${packageItem?.months} month(s)` : 'Permanent' }
              </p>
              <p className="m-0">
                Price: ${packageItem.selected_price}
              </p>
              <p className="m-0">
                Final price: ${packageItem.final_price}
              </p>
              <p className="m-0">
                Minecraft user required:{" "}
                {packageItem.is_minecraft_required ? "Yes" : "No"}
              </p>
              <p className="m-0">
                Discord user required:{" "}
                {packageItem.is_discord_required ? "Yes" : "No"}
              </p>
              <p className="m-0">
                Created by: {packageItem.created_by_username}
              </p>
              <p className="m-0">
                Created at:{" "}
                {new Date(packageItem.created_at).toLocaleString()}
              </p>
              <div className="mt-2">
                <Button
                  size="sm"
                  className="mr-2"
                  onClick={() => {
                    openNewUrl(packageItem.uuid);
                  }}>
                  Open in naizu.net
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    updatePackageStatus(
                      packageItem.uuid,
                      packageItem.active
                    );
                  }}
                  size="sm"
                  className="mr-2">
                  Set as {packageItem.active ? "inactive" : "active"}
                </Button>
                {(group == "owner" ||
                  userUuid == packageItem.created_by_uuid) && (
                  <Button
                    disabled={loading}
                    onClick={() => {
                      deletePackage(packageItem.uuid);
                    }}
                    size="sm"
                    variant="danger"
                    className="mr-2">
                    Delete
                  </Button>
                )}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}
