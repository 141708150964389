import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import "./Global.css";
import UserFinder from "./components/UserFinder";
import LogsPanel from "./components/LogsPanel";
import StatsPanel from "./components/StatsPanel";
import Login from "./components/Login";
import settings from "./settings";
import { Package } from "./components/Package";
import { ToolUsers } from "./components/ToolUsers";

const API_USERDATA_ENDPONT = `${settings.BASE_ENDPOINT}${settings.USERDATA_ENDPOINT}`;
const API_HYPIXELDATA_ENDPONT = `${settings.BASE_ENDPOINT}${settings.HYPIXELDATA_ENDPOINT}`;
const API_MODLOGS_ENDPONT = `${settings.BASE_ENDPOINT}${settings.MODLOGS_ENDPOINT}`;

interface MyProps {
  value?: any;
}
interface MyState {
  userInput?: any;
  loggedIn: boolean;
  isAdmin?: boolean;
  loading: boolean;
  error: any;
  botRestartReasonInput?: any;
  checking?: boolean;
  botRestartResult?: any;

  user: string;
  uuid: string;
  group: string;
  showModAdminModal: boolean;
  packageView: boolean;
  toolUserView: boolean;
}
class App extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.state = {
      userInput: "",
      loggedIn: false,
      loading: false,
      error: false,
      showModAdminModal: false,
      botRestartReasonInput: "",
      botRestartResult: "",
      isAdmin: false,

      user: "",
      uuid: "",
      group: "",
      packageView: false,
      toolUserView: false,
    };
    this.updateUserInput = this.updateUserInput.bind(this);
    this.callbackLoginFunction =
      this.callbackLoginFunction.bind(this);
    this.adminModal = this.adminModal.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendRequestForRestart =
      this.sendRequestForRestart.bind(this);
    this.navigateToPackage = this.navigateToPackage.bind(this);
    this.navigateToToolUser = this.navigateToToolUser.bind(this);
  }

  updateUserInput(val: string) {
    this.setState({ userInput: val });
  }

  async componentDidMount() {
    await this.loginState();
  }

  async callbackLoginFunction(logingChildData: any) {
    await this.setState({
      loggedIn: logingChildData.session,
      user: logingChildData.user,
      uuid: logingChildData.uuid,
      group: logingChildData.group,
      isAdmin: logingChildData.isAdmin,
    });
  }

  async handleInputChange(event: any) {
    await this.setState({
      botRestartReasonInput: event.target.value,
    });
  }

  async loginState() {
    await this.setState({ loading: true });
    let loginData = await fetch(
      `${settings.BASE_ENDPOINT}${settings.SESSION_ENDPOINT}`,
      {
        credentials: "include",
      }
    );
    if (loginData.status !== 200) {
      await this.setState({
        error: `Server issues. Try again later.`,
        loading: false,
      });
      return;
    }
    let l = await loginData.json();
    console.log(l);
    if (l.session) {
      await this.setState({
        loggedIn: true,
        user: l.user,
        uuid: l.uuid,
        group: l.group,
        isAdmin: l.isAdmin,
      });
    }
    await this.setState({ loading: false });
  }

  async logOut() {
    await fetch(`${settings.BASE_ENDPOINT}/logout`, {
      credentials: "include",
    });
    window.location.reload();
  }

  handleCloseModal(modal: string) {
    this.setState({ showModAdminModal: false });
  }
  handleOpenModal(modal: string) {
    this.setState({ showModAdminModal: true });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.sendRequestForRestart();
  }

  async navigateToPackage() {
    await this.setState({ packageView: true });
  }

  async navigateToToolUser() {
    await this.setState({ toolUserView: true });
  }

  async sendRequestForRestart() {
    if (this.state.botRestartReasonInput.length < 1) {
      return null;
    }
    await this.setState({ checking: true });
    let resp;
    let rjson;
    try {
      resp = await fetch(
        `${settings.BASE_ENDPOINT}/request-restart`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reason: this.state.botRestartReasonInput,
          }),
        }
      );
      if (resp.status !== 200)
        throw new Error("Status is " + resp.status);
      rjson = await resp.json();
      if (rjson.success) {
        await this.setState({
          botRestartResult: rjson,
          error: false,
        });
      } else {
        this.setState({ error: rjson.message });
      }
    } catch (error) {
      await this.setState({
        error: "Error trying to post - " + error.message,
      });
    }
    await this.setState({ checking: false });
  }

  displaySuccess(data: any, msg: string) {
    if (!data.success) {
      return;
    }
    return (
      <Alert variant="success" className="mt-3">
        <strong>Success!</strong> {msg}
      </Alert>
    );
  }

  displayError(error: string) {
    return (
      <Alert variant="danger" className="mt-3">
        <strong>Oops!</strong> {error}
      </Alert>
    );
  }

  adminModal(props: any) {
    let loading = () => {
      if (this.state.checking) {
        return (
          <div className="w-100 mt-4 text-center">
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
          </div>
        );
      }
    };
    return (
      <div>
        <Modal
          backdrop="static"
          size="lg"
          keyboard={false}
          show={this.state.showModAdminModal}
          onHide={() => {
            this.handleCloseModal("admin");
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-max p-2">
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Restart the housing bot</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    maxLength={250}
                    placeholder="Enter a reason"
                    disabled={this.state.checking}
                    value={this.state.botRestartReasonInput}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Button
                  variant="danger"
                  size="sm"
                  disabled={this.state.checking}
                  onClick={this.sendRequestForRestart}>
                  Restart bot
                </Button>
                {loading()}
                {!this.state.error
                  ? this.displaySuccess(
                      this.state.botRestartResult,
                      "Restart request sent"
                    )
                  : this.displayError("Unexpected error")}
                {/* <hr /> */}
              </Form>
              <hr />
              <p>More options</p>
              <Button
                className="mt-3"
                variant="info"
                size="sm"
                onClick={this.navigateToPackage}>
                Manage store packages
              </Button>
              <Button
                className="mt-3 ml-2"
                variant="info"
                size="sm"
                onClick={this.navigateToToolUser}>
                Manage mod tool access
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                this.handleCloseModal("admin");
              }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render() {
    if (!this.state.loggedIn) {
      return (
        <Login
          parentCallback={this.callbackLoginFunction}
          isLoading={this.state.loading}
          error={this.state.error}
        />
      );
    }

    if (this.state.packageView) {
      return (
        <Package uuid={this.state.uuid} group={this.state.group} />
      );
    } else if (this.state.toolUserView) {
      return (
        <ToolUsers />
      );
    } else {
      return (
        <Container fluid>
          <this.adminModal />
          <div className="position-relative font-weight-light">
            <small className="text-muted position-absolute mx-auto profile-info">
              Logged in as {this.state.user} —{" "}
              {this.state.isAdmin && (
                <span
                  onClick={() => this.handleOpenModal("admin")}
                  className="cbtn-link">
                  Options
                </span>
              )}
              {" - "}
              <span onClick={this.logOut} className="cbtn-link">
                Log out
              </span>
            </small>
          </div>

          <Row>
            <Col xs="5">
              <Row>
                <StatsPanel
                  handleUserInput={this.updateUserInput}></StatsPanel>
                <UserFinder
                  requestForUserFromExternal={this.state.userInput}
                />
              </Row>
            </Col>
            <LogsPanel handleUserInput={this.updateUserInput} />
          </Row>
        </Container>
      );
    }
  }
}

export default App;
