const settings = {
    NAIZU_PAGE_BASE: 'https://naizu.net',
    WEBSOCKET_ENDPOINT: 'https://server-pa1.jecsham.com',
    WEBSOCKET_ENDPOINT_: 'http://localhost:3001',
    WEBSOCKET_ENDPOINT_PATH: '/naizu-api-service',
    WEBSOCKET_ENDPOINT_PATH_: '',
    BASE_ENDPOINT: 'https://server-pa1.jecsham.com/naizu-api-service/api',
    BASE_ENDPOINT_: 'http://localhost:3001/api',
    USERDATA_ENDPOINT: '/checkuser/',
    MODLOGS_ENDPOINT: '/getmodlog/',
    HYPIXELDATA_ENDPOINT: '/checkuser-hypixel/',
    LOGS_ENDPOINT: '/get-latest-logs/',
    STATS_ENDPOINT: '/get-latest-stats/',
    SESSION_ENDPOINT: '/session/',
    LOGOUT_ENDPOINT: '/logout/',
}
export default settings
